<template>
  <v-container class="not-found">
    <v-row no-gutters style="height: 100%;">
      <v-col :align-self="'center'" justify-center class="text-center">
        <div class="text-vertical-center text-center primary--text mt-3 mb-6">
          <img
            alt="404"
            style="margin: 0 auto;"
            src="/img_layout/404.png"
            class="mb-3"
            height="100"
          />
          <h1 class="primary--text font-weight-semibold">
            Pagina non trovata!
          </h1>
          <p class="default--text font-weight-normal">
            Ci dispiace. La pagina che hai cercato non è stata trovata.<br />Ti
            invitiamo a effettuare una nuova ricerca.
          </p>

          <v-btn
            x-large
            color="primary"
            class="rounded-pill"
            depressed
            :to="{ name: 'Home' }"
          >
            TORNA ALLA HOMEPAGE
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "NotFound",
  mounted() {
    if (this.isCordova) {
      this.$router.push({
        name: "Home",
        path: "/"
      });
    }
  }
};
</script>
<style lang="scss">
.not-found {
  .text-vertical-center {
    text-align: center;
    vertical-align: middle;
    width: 100%;
  }
  .text-vertical-center h1 {
    margin: 0;
    padding: 0;
    color: $primary;
    margin: 0;
    padding: 18px 0;
    font: 900 35px;
  }
  .text-vertical-center h3 {
    margin: 0;
    padding: 5px 0;
    font: 800 22px;
  }
  .text-vertical-center h4 {
    margin: 0;
    padding: 0 0 30px 0;
    font: 400 20px;
  }
}
</style>
