<template>
  <v-row v-if="product" class="product-detail">
    <v-col cols="12" md="7" class="py-3 img-col">
      <div class="col-wrap white border pa-3 rounded-lg">
        <div class="promo-wrapper promo-wrapper-product-card">
          <div
            v-if="
              product.newProduct && !product.warehousePromo && !isHighlighted
            "
            class="new new-product text-uppercase white--text font-weight-semibold"
          >
            <div><v-icon color="white">$sparkle</v-icon></div>
            {{ $t("filter.new") }}
          </div>
          <div
            v-if="isHighlighted && !product.warehousePromo"
            class="product-highlighted-container"
          >
            <div class="highlighted-box-text">
              {{ $t("product.highlighted") }}
            </div>
          </div>
          <product-promo
            v-if="product.warehousePromo"
            :warehousePromo="product.warehousePromo"
          />
          <div v-if="isAuthenticated && product.warehousePromo">
            <div
              v-if="showPromoBubble"
              class="promo_bubble"
              :class="[{ 'promo-fidelity-bg': promoFidelity }, promoClass]"
              v-html="product.warehousePromo.view.bubble"
            ></div>
            <div
              v-else-if="product.warehousePromo.warehousePromoTypeId != 5"
              class="special-price"
            >
              <img src="/img_layout/special-price.png" alt="Prezzo Speciale" />
            </div>
          </div>
          <div v-else-if="!isAuthenticated">
            <div v-if="product.warehousePromo" class="special-price-anon">
              <span>{{ $t("product.promoAnon") }}</span>
            </div>
          </div>
          <product-gift-certificate
            v-for="userGiftCertificate in product.userGiftCertificates"
            :key="userGiftCertificate.giftCertificate.giftCertificateId"
            :giftCertificate="userGiftCertificate.giftCertificate"
            :containerRef="$refs.productCard"
            @add="giftAdded"
          />
        </div>
        <v-card flat v-if="selectedImage">
          <ProductImages
            :images="product.media"
            :name="product.name"
            :key="product.productId"
          />
        </v-card>
      </div>
    </v-col>
    <v-col
      cols="12"
      md="5"
      class="py-0 pb-3 py-sm-3 default--text"
      style="position:relative"
    >
      <div
        class="col-wrap pa-6 rounded-lg h-100"
        :class="{ 'white border': !$vuetify.breakpoint.xsOnly }"
      >
        <div class="d-flex align-center">
          <h2 class="pdp-product-name mt-0 mb-1">
            {{ product.name }}
          </h2>
        </div>
        <span class="short_descr font-weight-semibold">
          {{ product.shortDescr }}
        </span>
        <div class="my-2">
          <span class="descr" v-if="product.description">
            {{ product.description }}</span
          >
          <span
            v-if="
              product.productInfos?.TIPOLOGIA != 'Sfuso' &&
                product.priceUmDisplay &&
                product.weightUnitDisplay
            "
            class="weight-unit"
          >
            {{ $n(product.priceUmDisplay, "currency") }}/{{
              product.weightUnitDisplay
            }}
          </span>
          <div
            v-else-if="product.productInfos?.TIPOLOGIA == 'Sfuso'"
            class="d-flex align-center justify-center"
          >
            {{ minQuantity }} {{ unit }}
          </div>
          <!-- <span
            v-else-if="product.productInfos?.TIPOLOGIA == 'Sfuso'"
            class="weight-unit descr"
          >
            {{ minQuantity }} {{ unit }}
          </span> -->
        </div>

        <!-- <div class="code">
          <v-chip label>
            {{ product.codInt }}
          </v-chip>
        </div> -->
        <div
          class="characteristics"
          v-if="productClasses && productClasses.length > 0"
        >
          <div class="d-flex flex-wrap product-class-wraper mt-1">
            <ProductClass
              :pClass="pClass"
              :width="34"
              :height="34"
              v-for="pClass in productClasses"
              :key="pClass.productClassId"
            />
          </div>
        </div>

        <div class="d-flex align-center my-2">
          <ProductAcqLimit :product="product" />
          <product-lead-time
            class="ml-3"
            v-if="product.productInfos && product.productInfos.LEAD_TIME"
            :time="product.productInfos.LEAD_TIME"
            :showTooltip="true"
          />
        </div>
        <div
          class="option-wrapper package_num mt-2"
          v-if="product.productInfos?.SPLIT_ON_PACKAGE === 'true'"
        >
          <v-select
            class="options-wrapper package_num pt-2"
            outlined
            dense
            hide-details
            background-color="white"
            v-model="packageNum"
            :items="packagesNum"
            :label="$t('product.splitOnPackage')"
          />
        </div>
        <div
          class="option-wrapper mt-3 w-100"
          v-for="selectOption in selectOptionsArray"
          :key="selectOption.name"
          @click.stop.prevent
          @mousedown.stop
        >
          <v-select
            outlined
            dense
            full-width
            v-model="selectedOptions[selectOption.parameterName]"
            :items="selectOption.options"
            :label="selectOption.name"
            item-text="key"
            item-value="value"
            @change="optionChanged"
          />
        </div>

        <!-- <div
          class="fidelity-promo-label"
          v-if="promoFidelity && isAuthenticated"
        >
          <v-icon size="18" color="#158655" class="pr-1">$fidelity</v-icon>
          {{ $t("product.promoWithFidelity") }}
        </div> -->

        <div class="price-qty-wraper d-flex justify-space-between mt-3">
          <ProductPrice
            v-if="
              product.priceDisplay || product?.warehousePromo?.view?.MPieces
            "
            :product="product"
            :showStandardPrice="showStandardPrice"
            :showStandardDisplay="showStandardDisplay"
          ></ProductPrice>

          <v-spacer></v-spacer>
          <DayLock v-if="product.dayLock" :days="product.dayLock" />
          <LockCutoff
            v-if="
              product.productInfos && product.productInfos.PRODUCT_LOCK_CUTOFF
            "
            :hours="product.productInfos.PRODUCT_LOCK_CUTOFF"
          />
          <ProductQty
            v-if="product.available > 0"
            class="mt-3"
            :selectedOptions="selectedOptions"
            :product="product"
            :item="item"
            :showAddToCartLabel="true"
            :isProductDetail="true"
            :packageNum="packageNum"
            :label="
              $vuetify.breakpoint.smAndDown ? null : $t('products.addToCart')
            "
          />

          <div
            v-else-if="product.available == 0"
            class="product-not-available align-self-center"
          >
            <span
              v-if="product.productInfos && product.productInfos.LOCKER_LEVEL"
            >
              {{ $t("product.notAvailableForLocker") }}
            </span>
            <span
              v-if="
                product.dayLock ||
                  (product.productInfos &&
                    product.productInfos.PRODUCT_LOCK_CUTOFF)
              "
              >{{ $t("product.dayLock") }}</span
            >
            <span v-else>{{ $t("product.notAvailable") }}</span>
          </div>
        </div>
        <v-divider class="my-2" />
        <v-btn
          large
          depressed
          @click.stop.prevent="addToFavorites"
          @mousedown.stop
          class="font-weight-regular text-none mt-1 add-to-wishlist"
        >
          <transition name="pulse">
            <v-icon color="primary" :key="highlight" left>
              {{ highlight ? "$star" : "$starLight" }}
            </v-icon> </transition
          >{{ $t("product.addToList") }}
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.promo-fidelity-bg {
  background-color: #158655 !important;
}
.promo_mxn {
  background-color: #39a936 !important;
}
.promo_sotto {
  background: none !important;
}
.promo_imbattibili {
  background: none !important;
  .imbattibili {
    height: 64px;
  }
}
.promo_2x1 {
  background-color: #fddc2d !important;
}
.promo-fidelity-text {
  color: #158655 !important;
}
.product-detail {
  .promo-expire-date {
    left: 90px !important;
    min-width: 150px !important;
  }
  .product-lead-time-container {
    position: unset !important;
    top: unset !important;
    letf: unset !important;
  }
  .package_num {
    max-width: 90px !important;
    margin-left: 0px !important;
  }
  .product_promo {
    display: none;
  }
  .product-highlighted-container,
  .promo_bubble,
  .special-price {
    top: 3px !important;
    left: 16px !important;
    p {
      font-weight: 600;
    }
  }
  .special-price-anon {
    padding: 8px;
    background: #e11c24 0 0 no-repeat padding-box;
    display: flex !important;
    align-items: center;
    justify-items: center;
    width: 64px;
    height: 64px;
    position: absolute;
    z-index: 3;
    left: 16px;
    top: 3px;
    border-radius: 9px 0 6px 0 !important;
    span {
      transform: rotate(-45deg);
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
      position: relative;
      top: 0px;
      left: -7px;
    }
  }
  .pdp-product-name {
    font-size: 24px !important;
    line-height: unset !important;
    letter-spacing: 0px;
    font-weight: 400 !important;
  }
  .option-wrapper {
    max-width: 100%;
  }
  .new {
    background-color: #85cc34;
    width: 64px;
    height: 64px;
    position: absolute;
    top: 2px;
    left: 14px;
    border-radius: 9px 0px 6px 0px;
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
  .border {
    border: 1px solid #dadbdc !important;
  }
  .add-to-wishlist {
    background-color: white !important;
  }
  span.point::before {
    content: " \25CF";
    font-size: 20px;
    line-height: 12px;
  }
  .short_descr {
    font-size: 18px;
    font-weight: 600;
    // text-transform: uppercase;
  }
  .weight-unit.descr,
  .descr {
    background: #f8f9fa;
    border-radius: 6px;
    padding: 4px 6px;
  }

  .weight-unit {
    font-size: 13px;
  }
  .product-price {
    width: auto;
    .product_promo {
      position: relative;
      & > .card {
        bottom: 44px;
      }
    }
  }
  .promo_bubble {
    left: 80px;
    margin-top: 2px;
  }
  .vendorIcon {
    position: absolute;
    right: 6px;
    top: -18px;
    z-index: 6;
  }

  .price-um {
    font-size: 12px !important;
    margin-top: 5px !important;
  }
  .add-to-list-text.text-body-2 {
    font-size: 16px !important;
  }
  .description {
    font-weight: normal;
    font-size: 16px;
  }
  .code {
    .v-chip {
      font-size: 12px;
      height: 23px;
      background-color: #f5f5f5;
    }
  }
  .characteristics {
    span {
      font-size: 16px;
    }
  }
  .iva {
    font-size: 12px;
    white-space: nowrap;
  }
  .product-class-wraper {
    column-gap: 3px;
    row-gap: 5px;
    .product-class {
      img {
        height: 35px;
      }
    }
  }
  &-information {
    &-table tr {
      td:nth-child(1) {
        font-weight: bold;
      }
      td:nth-child(2) {
        padding-left: 10px;
      }
    }
  }
  .img-col {
    position: relative;
    .promo-wrapper {
      left: 4px;
      top: 20px;
      z-index: 4;
    }
  }
  .price-qty-wraper {
  }
  .max-acq {
    position: unset;
    display: block;
    width: fit-content;
    // margin-top: 10px;
  }
  .promo-wrapper.promo-wrapper-product-card {
    flex-direction: column;
  }
}
</style>
<script>
import ProductPrice from "@/components/product/ProductPrice.vue";
// import ProductPromo from "@/components/product/ProductPromo.vue";
import ProductClass from "@/components/product/ProductClass.vue";
import ProductImages from "@/components/product/ProductImages.vue";
import ProductQty from "./ProductQty.vue";
import ProductAcqLimit from "./ProductAcqLimit.vue";
import ProductGiftCertificate from "../gift/ProductGiftCertificate.vue";
import DayLock from "./ProductDayLock.vue";
import LockCutoff from "./ProductLockCutoff.vue";
import ProductPromo from "./ProductPromo.vue";
import ProductLeadTime from "./ProductLeadTime.vue";

// import ProductRating from "@/components/product/ProductRating.vue";
// import ProductCustomerVotes from "@/components/product/ProductCustomerVotes.vue";

import clickHandler from "~/mixins/clickHandler";
import productMixin from "~/mixins/product";
import downloadDocument from "~/mixins/downloadDocument";

import get from "lodash/get";
import { mapState, mapGetters } from "vuex";
import cloneDeep from "lodash/cloneDeep";
import { mapActions } from "vuex";

import cartService from "~/service/cartService";

export default {
  name: "ProductDetail",
  components: {
    ProductPrice,
    // ProductPromo,
    ProductClass,
    ProductImages,
    // ProductGiftCertificate,
    ProductQty,
    ProductAcqLimit,
    ProductGiftCertificate,
    ProductPromo,
    ProductLeadTime,
    DayLock,
    LockCutoff
  },
  mixins: [productMixin, clickHandler, downloadDocument],
  props: ["selectedImage", "product"],
  data() {
    return {
      imageDetailDialog: false,
      selectedOptions: {},
      selectOptionsArray: [],
      // promoFidelity: false,
      packagesNum: ["1", "2", "3", "4", "5"]
    };
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    packageNum: {
      get() {
        return get(this.item, "cartItemInfo.package_num", "1");
      },
      set(value) {
        this.setCartItemInfo({
          items: this.item,
          name: "package_num",
          value: "" + value
        });
      }
    },
    isHighlighted() {
      return this.product.productClasses.some(
        productClass => productClass.productClassId === 10000 // Prodotto in evidenza
      );
    },
    showStandardDisplay() {
      return (
        this.product.warehousePromo &&
        this.product.warehousePromo.view &&
        this.product.warehousePromo.view.MPieces != undefined
      );
    },
    showStandardPrice() {
      let result =
        (this.isAuthenticated &&
          this.product &&
          this.product.priceStandardDisplay &&
          this.product.priceStandardDisplay > 0.01 &&
          this.product.warehousePromo &&
          this.product.warehousePromo.warehousePromoTypeId &&
          this.product.warehousePromo.warehousePromoTypeId != 1 &&
          this.product.warehousePromo.warehousePromoTypeId != 2 &&
          this.product.warehousePromo.warehousePromoTypeId != 5 &&
          this.product.warehousePromo.warehousePromoTypeId != 6) ||
        (this.isAuthenticated &&
          this.product &&
          this.product.priceStandardDisplay &&
          this.product.priceStandardDisplay > 0.01 &&
          this.product.warehousePromo &&
          this.product.warehousePromo.warehousePromoTypeId &&
          this.product.warehousePromo.warehousePromoTypeId == 6 &&
          this.product.warehousePromo.view &&
          this.product.warehousePromo.view.hasPercentage === "true") ||
        (this.isAuthenticated &&
          this.product &&
          this.product.priceStandardDisplay &&
          this.product.priceStandardDisplay > 0.01 &&
          this.product.warehousePromo &&
          this.product.warehousePromo.warehousePromoTypeId == 6 &&
          this.product.warehousePromo.view &&
          this.product.warehousePromo.view.hasPercentage === undefined &&
          this.product.warehousePromo.view.MPieces === undefined &&
          this.product.warehousePromo.view.percentagePrice != undefined);
      if (result !== true) {
        result = false;
      }
      return result;
    },
    promoClass() {
      let productClasses = [];
      let cssClass = get(this.product, "warehousePromo.view.cssClass");
      if (cssClass) {
        productClasses.push("has-promo");
        productClasses.push(cssClass);
      }
      if (this.product.newProduct) {
        productClasses.push("new-product");
      }
      if (this.isHighlighted) {
        productClasses.push("highlited-product");
      }
      // if (this.product.edge) {
      //   productClasses.push(this.product.badge.cssClass);
      // }
      return productClasses;
    },
    minQuantity() {
      return cartService.plus(this.product);
    },
    item() {
      this.key;
      let item = this.getItem({
        product: this.product,
        ...this.selectedOptions
      });
      return item;
    },
    currentPageUrl() {
      return window.location.href;
    },
    showPromoBubble() {
      return (
        this.product.warehousePromo &&
        this.product.warehousePromo.view &&
        this.product.warehousePromo.view.bubble &&
        this.product.warehousePromo.warehousePromoTypeId &&
        [1, 6].includes(this.product.warehousePromo.warehousePromoTypeId)
      );
    },
    promoFidelity() {
      let cssClass = get(this.product, "warehousePromo.view.cssClass");
      return cssClass?.includes("promo_fidelity");
    },
    hasPercentage() {
      let cssClass = get(this.product, "warehousePromo.view.hasPercentage");
      return cssClass === "true";
    }
  },
  methods: {
    ...mapActions({
      setCartItemInfo: "cart/setCartItemInfo"
    }),
    downloadDocument(productId) {
      var url = window.event.currentTarget.href;
      this.handleReceiptDownload(url, productId);
    },
    goToProductCustomerVote() {
      // this.$vuetify.goTo(this.$refs.disclaimerform);
      this.$emit("goToCustomerVote", true);
    },
    selectImage(media) {
      this.$emit("selectImage", media);
    },
    openImageDialog() {
      this.imageDetailDialog = true;
    },
    setOptionsArray() {
      if (this.product.selectOptions && this.product.selectOptions.length) {
        this.selectOptionsArray = cloneDeep(this.product.selectOptions);
      } else {
        this.selectOptionsArray = [];
      }
    }
  },
  created() {
    this.setOptionsArray();
  },
  mounted() {
    // let cssClass = get(this.product, "warehousePromo.view.cssClass");
    // console.log("bbb fidx", cssClass);
    // this.promoFidelity = cssClass?.includes("promo_fidelity");
  },
  watch: {
    product(val, oldVal) {
      if (val.productId !== oldVal.productId) {
        this.setOptionsArray();
      }
    }
  }
};
</script>
